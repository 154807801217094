<template>
    <front-layout>
        <v-container>
            <v-row>
                <v-col>
                    <v-sheet elevation="1" class="px-5">
                        <v-row>
                            <v-col>
                                Menu
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col xl="12">
                                <h1>{{menu.title}}</h1>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col xl="12">
                                <v-img contain max-height="500px" :src="menu.file_url"/>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
    </front-layout>
</template>

<script>
import FrontLayout from "../layouts/FrontLayout";
import Menu from "@/models/Menu";
import VenueMenuView from "@/models/VenueMenuView";

export default {
    name: "menu-public",
    components: {FrontLayout},
    async mounted() {
        await this.init()
    },
    data() {
        return {
            menu: {},
            user: this.$auth.user()
        }
    },
    methods: {
        async init() {
            const menuSlug = this.$route.params.slug

            if (!menuSlug) {
                return;
            }

            this.menu = await Menu.custom('menus/slug/' + menuSlug).first()

            const menuView = new VenueMenuView()
            menuView.menu_id = this.menu.id;
            menuView.save()
        }
    }
}
</script>

<style scoped>

</style>
